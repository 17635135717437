<template>
    <div>
        <h1>Other Products</h1>
        <p>Explore more about our Arcanite products</p>
        <div class="cards">
            <div v-for="i in otherProductsA" :key="i">
                <div class="card" >
                    <div class="cardColor">
                        <span class="icons">
                            <img :src="i.icons" alt="" class="icons">
                        </span>
                        <div class="text">
                            <div class="title">
                                {{i.title}}
                            </div>
                            <div class="content">
                                {{i.content}}
                            </div>
                            <div class="btn" >
                              <button class="part5Button" @click="goRouter(i.path)"><div class="bottomText">View More</div><img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="picons"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cards twoCardsFormat">
            <div v-for="i,index in otherProductsB" :key="i.title">
                <div class="card">
                    <div class="cardColor">
                        <span class="icons">
                            <img :src="i.icons" alt="" class="icons">
                        </span>
                        <div class="text">
                            <div class="title">
                                {{i.title}}
                            </div>
                            <div class="content">
                                {{i.content}}
                            </div>
                            <div class="btn">
                              <button class="part5Button" @click="goRouter(i.path)"><div class="bottomText">View More</div><img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="picons"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default ({
    data() {
      return{
          otherProductsA:
          [
            {icons:require("./../../assets/imgs/pc/topNav/p1.png"),
            title:"Arcanite",
            content:"Mobile-focused platform for project marketing professionals",
            path:"/Arcanite"},
            {icons:require("./../../assets/imgs/pc/topNav/p2.png"),
            title:"Arcanite Connect",
            content:"Manage your sales operations from your phone",
            path:"/ArcaniteConnect"},
            {icons:require("./../../assets/imgs/pc/topNav/p3.png"),
            title:"Arcanite Partners",
            content:"Connect developers and agents with industry associates",
            path:"/ArcanitePartners"}],
          otherProductsB:
          [
            {icons:require("./../../assets/imgs/pc/topNav/p4.png"),
            title:"DragonVR",
            content:"Bring off-the-plan properties to life",
            path:"/DragonVR"},
            // {icons:require("./../../assets/imgs/pc/topNav/p5.png"),
            // title:"Equimate",
            // content:"Help Aussies buyers get into their dream home",
            // path:"/Equimate"},
            {icons:require("./../../assets/imgs/pc/topNav/p6.png"),
            title:"Project Marketplace",
            content:"Expand your network and discover new sales opportunities",
            path:"/Marketplace"},
            // {icons:require("./../../assets/imgs/pc/topNav/p5.png"),
            // title:"",
            // content:"",
            // path:""},
		  
		  ],
      }
  },
  methods:{
    goRouter(path){
       this.$router.push(path)
    },
  }
})
</script>
<style lang="scss" scoped>
  h1{
    margin: auto;
    padding-top: 126px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 36px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #062440;
  }
  p{
    margin-top: 10px;
    margin-bottom: 50px;
    height: 30px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #314455;
  }
  .cards{
    margin: 0 0 0 0;
    // width: 1210px;
    height: 238px;
    display: flex;
    justify-content: space-between;
    .card{
        width:368px;
        height: 178px;
        padding:20px;
        margin-top: 40px;
        // margin-left:40px;
        padding: 20px;
        display: flex;
        text-align: left;
        border-radius: 5px;
        position: relative;
        .cardColor{
            display: flex;
            text-align: left;
            width: 100%;
            height: 100%;
            .icons{
                width: 48px;
                height: 48px;
            }
            .text{
                margin:10px;
                margin-left: 20px;
                height: 100px;
                .title{
                    // margin-bottom: 20px;
                    height: 28px;
                    flex-grow: 0;
                    font-family: Poppins-Bold;
                    font-size: 20px;
                    // font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.4;
                    letter-spacing: normal;
                    text-align: left;
                    color: #062440;
                }
                .content{
                    width: 300px;
                    margin-top: 20px;
                    height: 50px;
                    // height: 48px;
                    flex-grow: 0;
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: #062440;
                }
            }
            .btn{
                display: none;
                margin: auto;
            }
            .part5Button{
                margin: 30px 0 0 150px;
                width: 149px;
                height: 42px;
                flex-grow: 0;
                display: flex;
                flex-direction: row;
                justify-content: right;
                align-items: right;
                gap: 10px;
                padding: 10px 16px;
                border-radius: 5px;
                background-color: #1890ff;
                position: absolute;
                right: 20px;
                bottom: 20px;
				white-space: nowrap;
                .bottomText{
                    height: 22px;
                    flex-grow: 0;
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                }
                .picons{
                    width: 22px;
                    height: 22px;
                }
            }
            .part5Button:hover{
                background-color: #096dd9;
            }        
            .part5Button:active{
                background-color: #0757ae;
            }
            
        }
    }
    .card:hover{
        background:#eef7ff;
    }
    .card:hover .btn{
        display: block;
    }
  }

  .twoCardsFormat{
    justify-content: flex-start;
  }
    
</style>
